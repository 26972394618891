let isEditMode = window.location.search.match(/editmode=true/)
let componentCss = ""
document.querySelectorAll("link").forEach( tag => {
  let url = tag.getAttribute("href")
  if(url.startsWith("/resources/css/site")){
    componentCss = url
  }
})

import {component} from "duo-kit";
import CookieBanner from "./components/cookie-banner.svelte"
component({component:CookieBanner,tagname:"cookie-banner",href:componentCss,shadow:isEditMode})

var $, clicklists, datalinks, jQuery, mmutationObserver, mo, riot, testimonials;

window.riot = riot = require('riot');

require('riot-kit/lib/gmap');
require('riot-kit/lib/dropdown');
require('./tags/navigation.tag');
require('./tags/counter.tag');
require('./libs/slideshow.coffee');

$ = jQuery = window.jQuery = window.$ = require("jquery");

require('owl.carousel');

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow');
});

mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

mo = new mmutationObserver(function(mutations) {
  var m, mutation, results;
  m = 0;
  results = [];
  while (m < mutations.length) {
    mutation = mutations[m];
    if (mutation.type === "attributes" && mutation.target.className.match(/navshowing/)) {
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach(function(subnav) {
        return subnav.addEventListener('click', function(e) {
          var subsecs;
          if (subnav.getAttribute('riot-tag') === "dropdown" && subnav.className.match(/open/)) {
            e.preventDefault();
            subnav.querySelector('a,i').addEventListener('click', function(e) {
              return window.location.href = subnav.querySelector('a').href;
            });
            subsecs = subnav.querySelectorAll('.subnav a');
            if (subsecs.length > 0) {
              return [].slice.call(subsecs).map((function(_this) {
                return function(sec) {
                  return sec.addEventListener('click', function(e) {
                    return window.location.href = sec.href;
                  });
                };
              })(this));
            }
          }
        });
      });
    }
    results.push(m++);
  }
  return results;
});

mo.observe(document.querySelector('body'), {
  attributes: true
});

clicklists = document.querySelectorAll('.dl_frontnews .item,.dl_news .item,.dl_imagelist .item,.dl_newsgrid .item');

if (clicklists.length > 0) {
  [].forEach.call(clicklists, function(cl) {
    return cl.addEventListener('click', function(e) {
      if (e.currentTarget.getAttribute('data-path')) {
        return window.location.href = e.currentTarget.getAttribute('data-path');
      }
    });
  });
}

testimonials = document.querySelectorAll('.dl_testimonials');

console.log(testimonials);

if (testimonials.length > 0) {
  [].slice.call(testimonials).map((function(_this) {
    return function(tst) {
      return tst.classList.add("owl-carousel");
    };
  })(this));
  setTimeout((function(_this) {
    return function() {
      return $(document).ready(function() {
        return $('.owl-carousel').owlCarousel({
          items: 1,
          pagination: true,
          nav: false,
          autoplay: true,
          loop: true,
          responsive: {
            0: {
              items: 1
            },
            992: {
              items: 1
            }
          }
        });
      });
    };
  })(this), 1000);
}

datalinks = document.querySelectorAll('.datalink');

[].forEach.call(datalinks, function(dlink) {
  return dlink.addEventListener('click', function(e) {
    if (e.currentTarget.getAttribute('data-path')) {
      return window.location.href = e.currentTarget.getAttribute('data-path');
    }
  });
});